import styled from 'styled-components';

import { PageBlock } from 'components/layout';
import { UpcomingPTOTable } from 'components/PTO/PTOTable';

const Page = styled.div`
  display: grid;
  grid-gap: 25px;
  grid-template-columns: repeat(1, 1fr);
`;

const UpcomingLayout = () => (
  <Page>
    <PageBlock>
      <UpcomingPTOTable />
    </PageBlock>
  </Page>
);

export default UpcomingLayout;
