import dayjs from 'lib/dayjs';
import { PTO_REASON_KEYS, PTO_STATUS_VALUE } from './reference';
import { parsedFloat } from './common';

export const parseBalanceByType = (balances, type, options = {}) => {
  const { year = dayjs().year() } = options;

  const currentBalances = balances.find(balance => balance.type === type);
  if (!currentBalances) return { totalBalance: 0, totalRemaining: 0, totalRequested: 0 };

  const totalRequested = currentBalances.ptoRequested[year] ?? 0;
  const totalRemaining = currentBalances.accumulatedAmount ?? 0;
  const totalBalance = totalRemaining + totalRequested;

  return {
    totalBalance,
    totalRemaining,
    totalRequested,
  };
};

// Gives us the total balance, remaining, and requested in 8 hour days instead of hourly
export const parseOfficeClosureBalance = (balances, options = {}) => {
  const { totalRemaining, totalRequested } = parseBalanceByType(balances, PTO_REASON_KEYS.office_closure, options);

  return {
    totalBalance: totalRemaining / 8,
    totalRemaining: (totalRemaining - totalRequested) / 8,
    totalRequested: totalRequested / 8,
  };
};

export const filterRequestsByStatus = (requests = [], status = PTO_STATUS_VALUE.approved) => {
  const statusArray = Array.isArray(status) ? status : [status];
  return requests.filter(request => {
    return statusArray.includes(request.mostRecentDetail?.status);
  });
};

export const filterRequestsByType = (requests = [], type = PTO_REASON_KEYS.pto) => {
  return requests.filter(request => {
    return request.mostRecentDetail?.reason === type;
  });
};

export const calculateRequestHours = (requests = []) => {
  const hours = requests.reduce((totalHours, currentRequest) => {
    const currentDetail = currentRequest?.mostRecentDetail;
    if (!currentDetail) return totalHours;

    const requestHours = currentDetail.hours ?? 0;
    return totalHours + parsedFloat(requestHours);
  }, 0);

  return hours;
};

export const calculateApprovedHours = (requests = []) => {
  const approvedRequests = filterRequestsByStatus(requests, PTO_STATUS_VALUE.approved);
  const approvedPtoRequests = filterRequestsByType(approvedRequests, PTO_REASON_KEYS.pto);

  return calculateRequestHours(approvedPtoRequests);
};

export const calculateYtdHours = (requests = []) => {
  const today = dayjs();

  const approvedPendingRequests = filterRequestsByStatus(requests, [
    PTO_STATUS_VALUE.approved,
    PTO_STATUS_VALUE.pending,
  ]);
  const approvedPendingPtoRequests = filterRequestsByType(approvedPendingRequests, PTO_REASON_KEYS.pto);
  const ytdRequests = approvedPendingPtoRequests.filter(request => {
    return dayjs(request.mostRecentDetail?.startDate).isSameOrBefore(today, 'day');
  });

  return calculateRequestHours(ytdRequests);
};

export const calculateOfficeClosures = (balances = [], options = {}) => {
  return parseOfficeClosureBalance(balances, options);
};
