import React, { Fragment } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { RequestPTOButton } from 'components/PTO/RequestPTOButton';

import SubHeader from 'components/SubHeader';
import { Container, View } from 'components/layout';
import PastLayout from 'components/PTO/PastLayout';
import UpcomingLayout from 'components/PTO/UpcomingLayout';
import PTOStats from './PTOStats';

export default ({ match }) => {
  const menuItems = [
    { to: `${match.path}/current`, display: 'Current & Upcoming' },
    { to: `${match.path}/history`, display: 'History' },
  ];

  return (
    <Fragment>
      <PTOStats />
      <SubHeader items={menuItems}>
        <RequestPTOButton />
      </SubHeader>
      <View>
        <Container>
          <Switch>
            <Route path={`${match.path}/current`} component={UpcomingLayout} />
            <Route path={`${match.path}/history`} component={PastLayout} />
            <Redirect from={match.path} to={`${match.path}/current`} />
          </Switch>
        </Container>
      </View>
    </Fragment>
  );
};
