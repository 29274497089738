import find from 'lodash/find';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import dayjs from 'lib/dayjs';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { DATE_FORMAT, SINGLE_DAY, MULTIPLE_DAY } from 'lib/constants';
import { updateUserPreferences, userPreferences } from 'modules/currentUser';
import { actions, selectors } from 'modules/pto';
import { selectors as referenceSelectors } from 'modules/reference';
import { checkValidity } from 'utils/validation';

import PTORequestModal from './PTORequestModal';
import { PTO_REASON_KEYS } from 'utils/reference';
import { parseOfficeClosureBalance } from 'utils/timeOff';

const MAX_COMMENT_LENGTH = 200;
class PTORequestModalContainer extends PureComponent {
  static defaultProps = {
    originalRequest: {
      reason: '',
      hoursWorkday: 8,
      includeWeekends: false,
      timeframeType: SINGLE_DAY,
      startDate: undefined,
      endDate: undefined,
      hours: undefined,
      managerComment: undefined,
      comment: '',
    },
    updating: false,
  };

  validations = {
    hoursWorkday: ['exists'],
    includeWeekends: ['exists'],
    reason: ['exists'],
    timeframeType: ['exists'],
    startDate: ['date'],
    endDate: ['date'],
    hours: ['number'],
    comment: ['exists', `maxlength:${MAX_COMMENT_LENGTH}`],
  };

  constructor(props) {
    // Snagging id to prevent spread onto state, eh?
    const { id, startDate, endDate, hoursWorkday, includeWeekends, ...rest } = props.originalRequest;
    const { updating, userPreferences } = props;

    const fallbackHoursWorkday = userPreferences.hoursWorkday ?? 8;
    const nextHoursWorkday = updating ? hoursWorkday : fallbackHoursWorkday;

    const fallbackIncludeWeekends = userPreferences.includeWeekends ?? false;
    const nextIncludeWeekends = updating ? includeWeekends : fallbackIncludeWeekends;

    super(props);

    this.state = {
      exclusions: [],
      selectedYear: startDate ? dayjs(startDate, DATE_FORMAT).year() : dayjs().year(),
      details: {
        ...rest,
        hoursWorkday: nextHoursWorkday,
        includeWeekends: nextIncludeWeekends,
        startDate: startDate ? dayjs(startDate, DATE_FORMAT) : undefined,
        endDate: endDate ? dayjs(endDate, DATE_FORMAT) : undefined,
      },
      confirmed: false,
      isDuplicateRequest: false,
    };
  }

  getSelectedYear = (startDate, endDate) => {
    const { timeframeType } = this.state.details;

    // Single Day - Use Start Date Year; Default to Current Year
    if (timeframeType === SINGLE_DAY) {
      if (!startDate) return dayjs().year();
      return startDate.year();
    }

    // Multi-Day - Determine Selected Year (Single or Same Year); Default to Current Year
    if (!startDate && !endDate) return dayjs().year();
    if (!startDate) return endDate.year();
    if (!endDate) return startDate.year();
    return startDate.year();
  };

  isExcludedDate = (exclusions, currentDate) =>
    exclusions.some(exclusion => dayjs(exclusion).isSame(currentDate, 'day'));

  countWeekdays = (startDate, endDate, weekdays, exclusions) => {
    let count = 0;
    let currentDate = dayjs(startDate);

    while (currentDate.isSameOrBefore(endDate, 'day')) {
      const isWeekday = weekdays.includes(currentDate.day());
      const isExcluded = this.isExcludedDate(exclusions, currentDate);

      if (isWeekday && !isExcluded) count++;

      currentDate = currentDate.add(1, 'day');
    }

    return count;
  };

  getDuration = (timeframeType, { startDate, endDate, hours = 0 }) => {
    if (timeframeType === SINGLE_DAY) return hours;
    if (!startDate || !endDate) return 0;

    const { hoursWorkday, includeWeekends } = this.state.details;

    const hoursPerDay = Number(hoursWorkday) ?? 8;
    const weekdays = includeWeekends ? [0, 1, 2, 3, 4, 5, 6] : [1, 2, 3, 4, 5];

    const totalDays = this.countWeekdays(startDate, endDate, weekdays, this.state.exclusions);

    return totalDays * hoursPerDay;
  };

  determineIfDuplicatePto = (ptos, startDate, endDate) => {
    return ptos.some(pto => {
      const overlapStart = startDate >= pto.mostRecentDetail.startDate && endDate <= pto.mostRecentDetail.endDate;
      const overlapEnd = endDate >= pto.mostRecentDetail.startDate && startDate <= pto.mostRecentDetail.endDate;
      const isOverlapPto = overlapStart || overlapEnd;

      return isOverlapPto;
    });
  };

  checkMultiPtoDuplicates = params => {
    const { originalStartDate, originalEndDate, startDate, endDate, updating, ptos, ptoId } = params;

    const isOriginalDates = originalStartDate === startDate && originalEndDate === endDate;

    // Prevent re-selecting original request dates from showing banner while updating.
    if (updating && isOriginalDates) return false;

    if (updating) {
      // Don't include PTO being updated in duplicate check.
      const filteredPto = ptos.filter(pto => pto.id !== ptoId);

      return this.determineIfDuplicatePto(filteredPto, startDate, endDate);
    }

    return this.determineIfDuplicatePto(ptos, startDate, endDate);
  };

  checkPartialPtoDuplicates = params => {
    const { originalStartDate, startDate, updating, ptos } = params;

    // Prevent re-selecting original request date from showing banner while updating.
    if (updating && originalStartDate === startDate) return false;

    return ptos.some(pto => startDate >= pto.mostRecentDetail.startDate && startDate <= pto.mostRecentDetail.endDate);
  };

  handleDuplicatePto = (key, date) => {
    let hasDuplicates;

    const {
      past,
      upcoming,
      updating,
      originalRequest: { startDate: originalStartDate, endDate: originalEndDate, ptoId },
    } = this.props;
    const {
      details: { timeframeType, startDate: inputStartDate, endDate: inputEndDate },
    } = this.state;

    const formattedStartDate = inputStartDate && inputStartDate.format(DATE_FORMAT);
    const formattedEndDate = inputEndDate && inputEndDate.format(DATE_FORMAT);

    const startDate = key === 'startDate' ? date : formattedStartDate;
    const endDate = key === 'endDate' ? date : formattedEndDate;

    const ptos = upcoming.length ? upcoming : past;

    const params = { originalStartDate, originalEndDate, startDate, endDate, updating, ptos, ptoId };

    if (timeframeType === SINGLE_DAY) hasDuplicates = this.checkPartialPtoDuplicates(params);
    if (timeframeType === MULTIPLE_DAY) hasDuplicates = this.checkMultiPtoDuplicates(params);

    this.setState(state => ({ ...state, isDuplicateRequest: hasDuplicates }));
  };

  updateInput = (key, value) => {
    const detailsUpdate = {};
    const stateUpdate = {};

    if (key === 'reason' && value === PTO_REASON_KEYS.office_closure) {
      detailsUpdate.timeframeType = SINGLE_DAY;
      detailsUpdate.hours = 8;
    }

    if (key === 'timeframeType') {
      detailsUpdate.endDate = undefined;
      detailsUpdate.hours = undefined;
    }

    if (key === 'startDate') stateUpdate.selectedYear = this.getSelectedYear(value, this.state.details.endDate);
    if (key === 'endDate') stateUpdate.selectedYear = this.getSelectedYear(this.state.details.startDate, value);

    if (key === 'startDate' || key === 'endDate') {
      const formattedValue = value && value.format(DATE_FORMAT);
      this.handleDuplicatePto(key, formattedValue);
    }

    this.setState(state => ({
      ...state,
      ...stateUpdate,
      details: {
        ...state.details,
        ...detailsUpdate,
        [key]: value,
      },
    }));
  };

  updateInputWithPreferences = (key, value) => {
    const { userPreferences, updateUserPreferences, updating } = this.props;
    this.updateInput(key, value);

    // Ignore preference update when this is an edit
    if (updating) return;

    updateUserPreferences({ ...userPreferences, [key]: value });
  };

  toggleConfirm = () => {
    this.setState(state => ({
      ...state,
      confirmed: !state.confirmed,
    }));
  };

  getValidations = (...keys) => {
    return keys.map(key => ({
      key,
      validate: this.validations[key],
    }));
  };

  validateStandardLimit = (details, balance) => {
    const { originalRequest } = this.props;

    const { timeframeType, startDate, endDate, hours } = details;
    const duration = this.getDuration(timeframeType, { startDate, endDate, hours });
    const accumulatedHours = Number(balance.accumulatedAmount || 0);
    const currentHours = Number(originalRequest.hours || 0);
    const available = accumulatedHours + currentHours;

    return duration > available;
  };

  validateOfficeClosureLimit = (details, balances) => {
    const { hours } = details;
    const { totalBalance, totalRequested } = parseOfficeClosureBalance(balances);
    const totalHours = hours / 8;

    return totalRequested + totalHours > totalBalance;
  };

  validateLimit = details => {
    const { balances } = this.props;
    const { reason } = details;
    if (!reason || reason === PTO_REASON_KEYS.pto) return false;

    const selectedBalance = find(balances, { type: reason });
    if (!selectedBalance) return false;

    if (reason === PTO_REASON_KEYS.office_closure) {
      return this.validateOfficeClosureLimit(details, balances);
    }

    return this.validateStandardLimit(details, selectedBalance);
  };

  validateFields = details => {
    const { timeframeType } = details;
    const standardValidation = this.getValidations('reason', 'timeframeType', 'comment');

    if (timeframeType === SINGLE_DAY) {
      return checkValidity(details, [...standardValidation, ...this.getValidations('startDate', 'hours')]);
    } else {
      return checkValidity(details, [...standardValidation, ...this.getValidations('startDate', 'endDate')]);
    }
  };

  validate = details => {
    const isFormValid = this.validateFields(details);
    const isAboveLimit = this.validateLimit(details);
    return isFormValid && !isAboveLimit;
  };

  submit = () => {
    const { originalRequest, updating, submitRequest, updateRequest } = this.props;
    const { startDate: dayStart, endDate: dayEnd, ...rest } = this.state.details;
    const params = pick(rest, ['hoursWorkday', 'includeWeekends', 'reason', 'timeframeType', 'hours', 'comment']);

    if (updating) {
      updateRequest(originalRequest.ptoId, {
        ...params,
        startDate: dayStart ? dayStart.format(DATE_FORMAT) : undefined,
        endDate: dayEnd ? dayEnd.format(DATE_FORMAT) : undefined,
      });
    } else {
      submitRequest({
        ...params,
        startDate: dayStart ? dayStart.format(DATE_FORMAT) : undefined,
        endDate: dayEnd ? dayEnd.format(DATE_FORMAT) : undefined,
      });
    }
  };

  getChildProps = () => ({
    ...omit(this.props, ['submitRequest']),
    ...this.state,
    MAX_COMMENT_LENGTH,
    getDuration: this.getDuration,
    getSelectedYear: this.getSelectedYear,
    onConfirm: this.toggleConfirm,
    onSubmit: this.submit,
    onUpdate: this.updateInput,
    onUpdatePreferences: this.updateInputWithPreferences,
    validate: this.getValidations,
    validateLimit: this.validateLimit,
    validation: this.validate,
  });

  render() {
    return <PTORequestModal {...this.getChildProps()} />;
  }
}

const mapStateToProps = state => ({
  balances: selectors.balances(state),
  loadingBalances: selectors.loadingBalances(state),
  loadingRequest: selectors.loadingRequest(state),
  holidays: referenceSelectors.holidays(state),
  userPreferences: userPreferences(state),
  upcoming: selectors.upcoming(state),
  past: selectors.past(state),
});

export default connect(mapStateToProps, {
  submitRequest: actions.submitRequest,
  updateRequest: actions.updateRequest,
  updateUserPreferences,
})(PTORequestModalContainer);
