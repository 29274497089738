import React, { PureComponent } from 'react';
import dayjs from 'lib/dayjs';

import { DATE_FORMAT, SINGLE_DAY } from 'lib/constants';

import PTOAdminModal from './PTOAdminModal';

class PTOAdminModalContainer extends PureComponent {
  static defaultProps = {
    details: {
      employeeName: '',
      reason: 'pto',
      timeframeType: SINGLE_DAY,
      startDate: undefined,
      endDate: undefined,
      hours: undefined,
      comment: '',
    },
  };

  constructor(props) {
    // Snagging id to prevent spread onto state, eh?
    const { id, startDate, endDate, ...rest } = props.details;

    super(props);

    this.state = {
      startDate: startDate ? dayjs(startDate, DATE_FORMAT) : undefined,
      endDate: endDate ? dayjs(endDate, DATE_FORMAT) : undefined,
      ...rest,
    };
  }

  getChildProps = () => ({
    ...this.props,
    ...this.state,
  });

  render() {
    return <PTOAdminModal {...this.getChildProps()} />;
  }
}

export default PTOAdminModalContainer;
