import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { actions, selectors } from 'modules/pto';

import PTOStats from './PTOStats';

class PTOBalanceContainer extends PureComponent {
  componentDidMount() {
    this.props.fetchBalances();
    this.props.fetchStatsPto();
  }

  render() {
    return <PTOStats {...this.props} />;
  }
}

const mapState = state => ({
  balances: selectors.balances(state),
  requests: selectors.statsPto(state),
});

const mapDispatch = {
  fetchStatsPto: actions.fetchStatsPto,
  fetchBalances: actions.fetchBalances,
};

export default connect(mapState, mapDispatch)(PTOBalanceContainer);
