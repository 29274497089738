import React from 'react';
import find from 'lodash/find';
import styled from 'styled-components';
import dayjs from 'lib/dayjs';

import { parsedFloat } from 'utils/common';
import { PTO_REASON_KEYS, PTO_STATUS_VALUE } from 'utils/reference';
import { calculateRequestHours, filterRequestsByStatus, filterRequestsByType } from 'utils/timeOff';

const StyledPTOBalance = styled.div`
  display: inline-flex;
  padding-right: 15px;

  .PTOBalance__description {
    margin-right: 15px;
  }

  .PTOBalance__value {
    font-weight: 700;
  }
`;

const calculateBalance = (yearBalances = {}, year) => {
  const requestedHours = yearBalances[year] ?? 0;
  return parsedFloat(requestedHours);
};

const calculateBalanceByRequests = (requests = []) => {
  const approvedPendingRequests = filterRequestsByStatus(requests, [
    PTO_STATUS_VALUE.pending,
    PTO_STATUS_VALUE.approved,
  ]);

  const approvedPendingPtoRequests = filterRequestsByType(approvedPendingRequests, PTO_REASON_KEYS.pto);

  return calculateRequestHours(approvedPendingPtoRequests);
};

const PTOBalance = ({ balances, requests, year = dayjs().year(), isViewingHistory }) => {
  const { ptoRequested } = find(balances, { type: 'pto' }) ?? {};

  const ytdRequested = isViewingHistory ? calculateBalanceByRequests(requests) : calculateBalance(ptoRequested, year);

  return (
    <StyledPTOBalance>
      <div className="PTOBalance__description">{year} Total Time Off</div>
      <div className="PTOBalance__value">{ytdRequested} Hours</div>
    </StyledPTOBalance>
  );
};

export default PTOBalance;
