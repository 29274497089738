import { all, takeLatest, call, put, select } from 'redux-saga/effects';
import { combineActions, createActions, handleActions } from 'redux-actions';
import dayjs from 'lib/dayjs';

import { actions as modalActions } from 'modules/modal';
import { actions as toastActions } from 'modules/toast';
import * as api from 'utils/api';

const defaultState = {
  balances: [],
  cached: {},
  cachedSearch: {},
  isViewingHistory: false,
  loading: true,
  loadingBalances: true,
  loadingRequest: false,
  loadingStats: true,
  statsRequests: [],
  past: [],
  teamPto: [],
  teamPtoOverview: [],
  teamPtoOverviewYear: dayjs().year(),
  upcoming: [],
  year: dayjs().year(),
};

export const PTO_REQUEST_MODAL = 'PTO_REQUEST_MODAL';

// Actions
const ADMIN_ACTION = (id, managerComment, managerId) => {
  return {
    id,
    managerComment,
    managerId,
  };
};

const ADMIN_BULK_ACTION = (ptoIds, managerComment, managerId) => {
  return {
    ptoIds,
    managerComment,
    managerId,
  };
};

export const actions = createActions(
  {
    UPDATE_REQUEST: (id, details) => ({
      id,
      details,
    }),
    ADMIN_APPROVE: ADMIN_ACTION,
    ADMIN_REJECT: ADMIN_ACTION,
    ADMIN_APPROVE_BULK: ADMIN_BULK_ACTION,
    ADMIN_REJECT_BULK: ADMIN_BULK_ACTION,
  },
  'FETCH_UPCOMING_PTO',
  'FETCH_UPCOMING_PTO_SUCCESS',
  'FETCH_PAST_PTO',
  'FETCH_PAST_PTO_SUCCESS',
  'FETCH_PTO_FAILURE',
  'FETCH_STATS_PTO',
  'FETCH_STATS_PTO_SUCCESS',
  'FETCH_STATS_PTO_FAILURE',
  'FETCH_BALANCES',
  'FETCH_BALANCES_SUCCESS',
  'FETCH_BALANCES_FAILURE',
  'REFRESH_PTO',
  'SUBMIT_REQUEST',
  'DELETE_REQUEST',
  'PTO_REQUEST_FAILURE',
  'PTO_REQUEST_SUCCESS',
  'ADMIN_TEAM_PTO',
  'ADMIN_TEAM_PTO_SUCCESS',
  'ADMIN_TEAM_PTO_FAILURE',
  'ADMIN_TEAM_PTO_OVERVIEW',
  'ADMIN_TEAM_PTO_OVERVIEW_SUCCESS',
  'ADMIN_TEAM_PTO_OVERVIEW_FAILURE',
  'ADMIN_APPROVE_SUCCESS',
  'ADMIN_APPROVE_BULK_SUCCESS',
  'ADMIN_REJECT_SUCCESS',
  'ADMIN_REJECT_BULK_SUCCESS'
);

// Selectors
export const selectors = {
  balances: state => state.pto.balances,
  cached: state => state.pto.cached,
  cachedSearch: state => state.pto.cachedSearch,
  isViewingHistory: state => state.pto.isViewingHistory,
  loading: state => state.pto.loading,
  loadingBalances: state => state.pto.loadingBalances,
  loadingRequest: state => state.pto.loadingRequest,
  loadingStats: state => state.pto.loadingStats,
  past: state => state.pto.past,
  statsPto: state => state.pto.statsPto,
  teamPto: state => state.pto.teamPto,
  teamPtoOverview: state => state.pto.teamPtoOverview,
  teamPtoOverviewYear: state => state.pto.teamPtoOverviewYear,
  upcoming: state => state.pto.upcoming,
  year: state => state.pto.year,
};

// Helpers
const toast = (type, title, message) =>
  toastActions.createToast(type, {
    title,
    message,
  });

// sagas
function* fetchUpcomingSaga({ payload }) {
  try {
    const { ptos } = yield call(api.fetchUpcomingPto, payload);

    if (ptos) {
      yield put(actions.fetchUpcomingPtoSuccess(ptos));
    }
  } catch (e) {
    console.error(e);
    yield put(actions.fetchPtoFailure());
  }
}

function* fetchPastSaga({ payload }) {
  try {
    const { ptos } = yield call(api.fetchPastPto, payload);

    if (ptos) {
      yield put(actions.fetchPastPtoSuccess(ptos));
    }
  } catch (e) {
    console.error(e);
    yield put(actions.fetchPtoFailure());
  }
}

function* fetchStatsPtoSaga() {
  try {
    const year = dayjs().year();
    const { ptos } = yield call(api.fetchPastPto, { year });

    if (ptos) {
      yield put(actions.fetchStatsPtoSuccess(ptos));
    }
  } catch (e) {
    console.error(e);
    yield put(actions.fetchStatsPtoFailure());
  }
}

function* fetchBalancesSaga({ payload }) {
  try {
    const { balances } = yield call(api.fetchBalances, payload);

    if (balances) {
      yield put(actions.fetchBalancesSuccess(balances));
    }
  } catch (e) {
    console.error(e);
    yield put(actions.fetchBalancesFailure());
  }
}

function* refreshPtoSaga() {
  try {
    const { data, type } = yield select(selectors.cachedSearch);

    if (type === 'PAST') {
      yield put(actions.fetchPastPto(data));
    } else {
      yield put(actions.fetchUpcomingPto(data));
    }
  } catch (e) {
    console.error(e);
    yield put(actions.fetchBalancesFailure());
  }
}

function* submitRequestSaga({ payload }) {
  try {
    yield call(api.submitPtoRequest, payload);
    yield put(actions.refreshPto());
    yield put(actions.fetchBalances());
    yield put(actions.fetchStatsPto());
    yield put(modalActions.closeModal(PTO_REQUEST_MODAL));
    yield put(actions.ptoRequestSuccess());
    yield put(toast('success', 'Submit Success', 'Request was submitted successfully!'));
  } catch (e) {
    console.error(e);
    yield put(actions.ptoRequestFailure());
    yield put(toast('alert', 'Submit Failed', 'Failed to submit! Please retry shortly or refresh the page.'));
  }
}

function* updateRequestSaga({ payload }) {
  try {
    yield call(api.updatePtoRequest, payload.id, payload.details);
    yield put(actions.refreshPto());
    yield put(actions.fetchBalances());
    yield put(actions.fetchStatsPto());
    yield put(modalActions.closeModal(PTO_REQUEST_MODAL));
    yield put(actions.ptoRequestSuccess());
    yield put(toast('success', 'Update Success', 'Request was updated successfully!'));
  } catch (e) {
    console.error(e);
    yield put(actions.ptoRequestFailure());
    yield put(toast('alert', 'Update Failed', 'Failed to update! Please retry shortly or refresh the page.'));
  }
}

function* deleteRequestSaga({ payload }) {
  try {
    yield call(api.deletePtoRequest, payload);
    yield put(actions.refreshPto());
    yield put(actions.fetchBalances());
    yield put(actions.fetchStatsPto());
    yield put(actions.ptoRequestSuccess());
    yield put(toast('success', 'Delete Success', 'Request was deleted successfully!'));
  } catch (e) {
    console.error(e);
    yield put(actions.ptoRequestFailure());
    yield put(toast('alert', 'Delete Failed', 'Failed to delete! Please retry shortly or refresh the page.'));
  }
}

// Admin
function* adminTeamPtoSaga({ payload }) {
  try {
    const { admin: cached } = yield select(selectors.cached);
    const { ptos } = yield call(api.fetchTeamPto, { ...cached, ...payload });

    if (ptos) {
      yield put(actions.adminTeamPtoSuccess(ptos));
    }
  } catch (e) {
    console.error(e);
    yield put(
      toast(
        'alert',
        'Team PTO Fetch Failed',
        'Failed to fetch pto requests for your team! Please retry shortly or refresh the page.'
      )
    );
    yield put(actions.adminTeamPtoFailure());
  }
}

function* adminTeamPtoOverviewSaga({ payload }) {
  try {
    const year = payload?.year || (yield select(selectors.teamPtoOverviewYear));
    const { team } = yield call(api.fetchTeamPtoOverview, { year, managerId: payload?.managerId });

    if (team) {
      yield put(actions.adminTeamPtoOverviewSuccess({ team, year }));
    }
  } catch (e) {
    console.error(e);
    yield put(
      toast(
        'alert',
        'Team PTO Overview Fetch Failed',
        'Failed to fetch pto overview for your team! Please retry shortly or refresh the page.'
      )
    );
    yield put(actions.adminTeamPtoOverviewFailure());
  }
}

function* adminApproveSaga({ payload }) {
  try {
    const { admin: cache } = yield select(selectors.cached);

    yield call(api.adminApprovePto, payload);
    yield put(actions.adminTeamPto(cache));
    yield put(toast('success', 'Approval Success', 'Request was approved successfully!'));
  } catch (e) {
    yield put(toast('alert', 'Approval Failed', 'Failed to approve! Please retry shortly or refresh the page.'));
  }
}

function* adminApproveBulkSaga({ payload }) {
  try {
    const { admin: cache } = yield select(selectors.cached);

    yield call(api.adminApproveBulkPto, payload);
    yield put(actions.adminTeamPto(cache));
    yield put(toast('success', 'Approval Success', 'Bulk approval was successful!'));
  } catch (e) {
    yield put(toast('alert', 'Approval Failed', 'Failed to approve! Please retry shortly or refresh the page.'));
  }
}

function* adminRejectSaga({ payload }) {
  try {
    const { admin: cache } = yield select(selectors.cached);

    yield call(api.adminRejectPto, payload);
    yield put(actions.adminTeamPto(cache));
    yield put(toast('success', 'Reject Success', 'Request was rejected successfully!'));
  } catch (e) {
    yield put(toast('alert', 'Reject Failed', 'Failed to reject! Please retry shortly or refresh the page.'));
  }
}

function* adminRejectBulkSaga({ payload }) {
  try {
    const { admin: cache } = yield select(selectors.cached);

    yield call(api.adminRejectBulkPto, payload);
    yield put(actions.adminTeamPto(cache));
    yield put(toast('success', 'Reject Success', 'Bulk rejection was successful!'));
  } catch (e) {
    yield put(toast('alert', 'Reject Failed', 'Failed to reject! Please retry shortly or refresh the page.'));
  }
}

export function* ptoSaga() {
  yield all([
    takeLatest(actions.fetchUpcomingPto, fetchUpcomingSaga),
    takeLatest(actions.fetchPastPto, fetchPastSaga),
    takeLatest(actions.fetchStatsPto, fetchStatsPtoSaga),
    takeLatest(actions.fetchBalances, fetchBalancesSaga),
    takeLatest(actions.refreshPto, refreshPtoSaga),
    takeLatest(actions.submitRequest, submitRequestSaga),
    takeLatest(actions.updateRequest, updateRequestSaga),
    takeLatest(actions.deleteRequest, deleteRequestSaga),

    // Admin
    takeLatest(actions.adminTeamPto, adminTeamPtoSaga),
    takeLatest(actions.adminTeamPtoOverview, adminTeamPtoOverviewSaga),
    takeLatest(actions.adminApprove, adminApproveSaga),
    takeLatest(actions.adminApproveBulk, adminApproveBulkSaga),
    takeLatest(actions.adminReject, adminRejectSaga),
    takeLatest(actions.adminRejectBulk, adminRejectBulkSaga),
  ]);
}

// reducer
export default handleActions(
  {
    // Fetch
    [actions.fetchUpcomingPto](state) {
      const year = dayjs().year();
      const cachedSearch = { data: { year }, type: 'UPCOMING' };
      return { ...state, cachedSearch, past: [], upcoming: [], year, isViewingHistory: false, loading: true };
    },
    [actions.fetchPastPto](state, { payload }) {
      const year = payload?.year ?? dayjs().year();
      const cachedSearch = { data: { year }, type: 'PAST' };
      return { ...state, cachedSearch, past: [], upcoming: [], year, isViewingHistory: true, loading: true };
    },
    [actions.fetchStatsPto](state) {
      return { ...state, loadingStats: true };
    },
    [actions.fetchPtoFailure](state) {
      return { ...state, loading: false };
    },
    [actions.fetchPastPtoSuccess](state, { payload: ptos }) {
      return { ...state, loading: false, past: ptos };
    },
    [actions.fetchUpcomingPtoSuccess](state, { payload: ptos }) {
      return { ...state, loading: false, upcoming: ptos };
    },
    [actions.fetchStatsPtoSuccess](state, { payload: ptos }) {
      return { ...state, loadingStats: false, statsPto: ptos };
    },
    [actions.fetchStatsPtoFailure](state) {
      return { ...state, loadingStats: false };
    },

    // Balances
    [actions.fetchBalances](state) {
      return { ...state, loadingBalances: true, balances: [] };
    },
    [actions.fetchBalancesSuccess](state, { payload: balances }) {
      return { ...state, loadingBalances: false, balances };
    },
    [actions.fetchBalancesFailure](state) {
      return { ...state, loadingBalances: false };
    },

    // Admin
    [actions.adminTeamPto](state, { payload }) {
      const cached = { ...state.cached, admin: payload ? payload : undefined };
      return { ...state, loading: true, cached, teamPto: [] };
    },
    [actions.adminTeamPtoFailure](state) {
      return { ...state, loading: false };
    },
    [actions.adminTeamPtoSuccess](state, { payload: ptos }) {
      return { ...state, loading: false, teamPto: ptos };
    },

    [actions.adminTeamPtoOverview](state) {
      return { ...state, loading: true };
    },
    [actions.adminTeamPtoOverviewFailure](state) {
      return { ...state, loading: false };
    },
    [actions.adminTeamPtoOverviewSuccess](state, { payload }) {
      const { team, year } = payload;
      return { ...state, loading: false, teamPtoOverview: team, teamPtoOverviewYear: year };
    },

    // Requests
    [combineActions(actions.deleteRequest, actions.submitRequest, actions.updateRequest)](state) {
      return { ...state, loadingRequest: true };
    },
    [combineActions(actions.ptoRequestFailure, actions.ptoRequestSuccess)](state) {
      return { ...state, loadingRequest: false };
    },
  },
  defaultState
);
