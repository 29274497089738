import React, { useState } from 'react';
import dayjs from 'lib/dayjs';

import { DATE_FORMAT_READABLE, SINGLE_DAY } from 'lib/constants';
import { PTO_REASONS, PTO_STATUS_VALUE, PTO_STATUS_LABEL } from 'utils/reference';
import { updateUserPreferences, userPreferences } from 'modules/currentUser';

import { Comment, Grid } from './PTOAdminModal.styled';
import ModalWrapper, { MODAL_TYPES } from 'components/Modals/ModalWrapper';
import LabeledInput from 'components/LabeledInput';
import { colors } from 'theme/theme';
import { BasicCalendar } from 'components/BasicCalendar/BasicCalendar';
import { Section } from 'components/Section';
import TextArea from 'components/TextArea';
import { ButtonIcon } from 'components/ButtonIcon';
import Select from 'components/Select';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const MIN_COMMENT_LENGTH = 1;
const MAX_COMMENT_LENGTH = 200;

const getEmphasisStyles = isEmphasized => {
  if (!isEmphasized) return undefined;
  return { borderLeft: `3px solid ${colors.orangeGambage}`, paddingLeft: 5 };
};

const getInitialStatus = status => {
  if (status === PTO_STATUS_VALUE.pending) return null;
  return status;
};

const getResponseType = selectedResponse => {
  if (!selectedResponse) return MODAL_TYPES.info;
  return selectedResponse === PTO_STATUS_VALUE.rejected ? MODAL_TYPES.alert : MODAL_TYPES.success;
};

const getResponseText = initialStatus => {
  if (initialStatus === PTO_STATUS_VALUE.pending) return 'Submit Response';
  return 'Update Response';
};

const PTOAdminModal = ({
  employeeName,
  balances,
  reason,
  timeframeType,
  hoursWorkday,
  includeWeekends,
  startDate,
  endDate,
  hours,
  comment,
  status,
  onClose,
  ...rest
}) => {
  const dispatch = useDispatch();
  const currentUserPreferences = useSelector(userPreferences);
  const [adminComment, setAdminComment] = useState('');
  const [adminResponse, setAdminResponse] = useState(getInitialStatus(status));
  const [isCalendarVisible, setIsCalendarVisible] = useState(currentUserPreferences.isPtoCalendarVisible ?? false);

  const isDayHighlighted = useCallback(
    day => {
      if (!day.moment) return false;
      if (day.isWeekend && !includeWeekends) return false;
      return startDate.isSameOrBefore(day.moment) && endDate.isSameOrAfter(day.moment);
    },
    [startDate, endDate, includeWeekends]
  );

  const validate = useCallback(() => {
    if (!adminResponse || adminResponse === status) return false;
    if (adminResponse === PTO_STATUS_VALUE.rejected) return adminComment.length >= MIN_COMMENT_LENGTH;
    return adminComment.length <= MAX_COMMENT_LENGTH;
  }, [adminComment, adminResponse, status]);

  const onToggleCalendar = useCallback(() => {
    const nextVisible = !isCalendarVisible;
    setIsCalendarVisible(nextVisible);

    dispatch(updateUserPreferences({ ...currentUserPreferences, isPtoCalendarVisible: nextVisible }));
  }, [currentUserPreferences, dispatch, isCalendarVisible]);

  const onSubmit = useCallback(() => {
    onClose(adminResponse, adminComment);
  }, [adminComment, adminResponse, onClose]);

  // Render Hours per Workday and Weekends fields if user is not standard workflow
  const renderContractorDetails = () => {
    const isWeekendRequest = includeWeekends;
    const isStandardHours = hoursWorkday === 8;

    // Standard User/Request, No Render
    if (!isWeekendRequest && isStandardHours) return null;

    return (
      <>
        <LabeledInput label="Hours Per Workday" style={getEmphasisStyles(!isStandardHours)}>
          <p>{hoursWorkday}</p>
        </LabeledInput>
        <LabeledInput label="Includes Weekends" style={getEmphasisStyles(isWeekendRequest)}>
          <p>{includeWeekends ? 'Yes' : 'No'}</p>
        </LabeledInput>
      </>
    );
  };

  const renderDurationInputs = () => {
    const renderCalendarButton = () => {
      return (
        <ButtonIcon
          style={{ color: colors.blueDenim, padding: '0 6px', marginLeft: 4 }}
          size="s"
          icon={isCalendarVisible ? 'eye-slash' : 'eye'}
          onClick={onToggleCalendar}
          transparent
        >
          {isCalendarVisible ? 'Hide' : 'Preview'}
        </ButtonIcon>
      );
    };

    if (timeframeType === SINGLE_DAY) {
      return (
        <>
          <LabeledInput label="Date" style={{ gridColumn: '1 / span 2' }}>
            <p>
              {dayjs(startDate).format(DATE_FORMAT_READABLE)} {renderCalendarButton()}
            </p>
          </LabeledInput>
          {renderCalendar()}
        </>
      );
    } else {
      return (
        <>
          <LabeledInput label="Dates" style={{ gridColumn: '1 / span 2' }}>
            <p>
              {dayjs(startDate).format(DATE_FORMAT_READABLE)} - {dayjs(endDate).format(DATE_FORMAT_READABLE)}{' '}
              {renderCalendarButton()}
            </p>
          </LabeledInput>
          {renderCalendar()}
        </>
      );
    }
  };

  const renderCalendar = () => {
    if (!isCalendarVisible) return null;
    return (
      <div style={{ gridColumn: '1 / span 2' }}>
        <BasicCalendar startDate={startDate} endDate={endDate} isDayHighlighted={isDayHighlighted} />
      </div>
    );
  };

  const renderDetails = () => (
    <Section>
      <Grid>
        <LabeledInput label="Employee" style={{ gridColumn: '1 / span 2' }}>
          <p>{employeeName}</p>
        </LabeledInput>
        <LabeledInput label="Reason">
          <p>{PTO_REASONS[reason]}</p>
        </LabeledInput>
        <LabeledInput label="Hours Requested">
          <p>{hours}</p>
        </LabeledInput>
        {renderContractorDetails()}
        {renderDurationInputs()}
        <LabeledInput label="Employee Comment" style={{ gridColumn: '1 / span 2' }}>
          <p>{comment}</p>
        </LabeledInput>
      </Grid>
    </Section>
  );

  const renderAdmin = () => {
    const commentRemaining = MAX_COMMENT_LENGTH - adminComment.length;

    return (
      <Section label="Approver Response">
        <LabeledInput label="Time Off Status" style={{ marginBottom: 20 }}>
          <Select
            options={[
              { label: PTO_STATUS_LABEL.approved, value: PTO_STATUS_VALUE.approved },
              { label: PTO_STATUS_LABEL.rejected, value: PTO_STATUS_VALUE.rejected },
            ]}
            placeholder="Select a Response"
            onChange={value => setAdminResponse(value)}
            value={adminResponse}
            autoFocus
          />
        </LabeledInput>
        <LabeledInput
          label={
            <span>
              Comment{' '}
              {
                <Comment style={{ display: 'inline-flex', fontSize: 11, fontWeight: 300 }}>
                  - {adminResponse === PTO_STATUS_VALUE.rejected ? 'Required' : 'Optional'}
                </Comment>
              }
            </span>
          }
        >
          <TextArea onChange={e => setAdminComment(e.target.value)} value={adminComment} />
          <Comment>
            Maximum {MAX_COMMENT_LENGTH} characters{' '}
            <span style={{ color: commentRemaining >= 0 ? 'inherit' : colors.redBrick }}>
              ({commentRemaining} remaining)
            </span>
          </Comment>
        </LabeledInput>
      </Section>
    );
  };

  return (
    <ModalWrapper
      type={getResponseType(adminResponse)}
      title="Time Off Details"
      closeText={getResponseText(status)}
      cancelButton={false}
      width={400}
      valid={validate()}
      onClose={onSubmit}
      showButtonType
      {...rest}
    >
      {renderDetails()}
      {renderAdmin()}
    </ModalWrapper>
  );
};

export default PTOAdminModal;
