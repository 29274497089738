export const referenceToOptions = (obj = {}, meta = {}) =>
  Object.keys(obj)
    .map(key => ({
      ...meta[key],
      label: obj[key],
      value: key,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

export const optionsToGroups = (options = [], groups = [], defaultLabel = 'Other') => {
  if (!groups.length) return options;

  const grouped = options.reduce((result, option) => {
    const groupsWithOption = groups.filter(({ options: keys }) => keys.includes(option.value));
    const isInGroup = !!groupsWithOption.length;

    if (!isInGroup) {
      result.push(option);
      return result;
    }

    groupsWithOption.forEach(({ label, options: keys }) => {
      const groupOptions = keys.map(key => options.find(option => option.value === key));
      const groupOption = { label, options: groupOptions };
      const groupIndex = result.findIndex(resultOption => resultOption.label === label);
      const doesGroupExist = groupIndex !== -1;

      if (doesGroupExist) {
        result.splice(groupIndex, 1, groupOption);
      } else {
        result.push(groupOption);
      }
    });

    return result;
  }, []);

  const optionsInGroups = grouped.filter(option => option.options);
  const optionsRemaining = grouped.filter(option => !option.options);
  const defaultGroup = { label: defaultLabel, options: optionsRemaining };
  const groupedOptions = [...optionsInGroups, defaultGroup];

  return groupedOptions;
};

export const PTO_REASON_KEYS = {
  bereavement: 'bereavement',
  expanded_fmla_ffcra: 'expanded_fmla_ffcra',
  fmla: 'fmla',
  jury_duty: 'jury_duty',
  long_term_disability: 'long_term_disability',
  maternity_leave: 'maternity_leave',
  office_closure: 'office_closure',
  paid_sick_leave_ffcra: 'paid_sick_leave_ffcra',
  paternity_leave: 'paternity_leave',
  pto: 'pto',
  short_term_disability: 'short_term_disability',
  unpaid_time_off: 'unpaid_time_off',
};

export const PTO_REASONS = {
  [PTO_REASON_KEYS.bereavement]: 'Bereavement',
  [PTO_REASON_KEYS.expanded_fmla_ffcra]: 'Expanded FMLA (FFCRA)',
  [PTO_REASON_KEYS.fmla]: 'FMLA',
  [PTO_REASON_KEYS.jury_duty]: 'Jury Duty',
  [PTO_REASON_KEYS.long_term_disability]: 'Long Term Disability',
  [PTO_REASON_KEYS.maternity_leave]: 'Maternity Leave',
  [PTO_REASON_KEYS.office_closure]: 'Office Closure',
  [PTO_REASON_KEYS.paid_sick_leave_ffcra]: 'Paid Sick Leave (FFCRA)',
  [PTO_REASON_KEYS.paternity_leave]: 'Paternity Leave',
  [PTO_REASON_KEYS.pto]: 'PTO',
  [PTO_REASON_KEYS.short_term_disability]: 'Short Term Disability',
  [PTO_REASON_KEYS.unpaid_time_off]: 'Unpaid Time Off',
};

export const ACTIVE_PTO_REASONS = (() => {
  const { paid_sick_leave_ffcra, expanded_fmla_ffcra, unpaid_time_off, ...filteredReasons } = PTO_REASONS;
  return filteredReasons;
})();

export const PTO_TYPES = {
  partial_day: 'Day',
  multiple_days: 'Multiple Days',
};

export const TIME_OFF_STATUS_MESSAGES = {
  pending: 'Waiting for Approval',
  approved: 'Approved',
  rejected: 'Rejected',
};

export const TIMESHEET_STATUS_MESSAGES = {
  pending: 'Waiting for Approval',
  unapproved: 'Unapproved',
  unsubmitted: 'Not Submitted',
  rejected: 'Rejected',
};

export const PTO_STATUS_LABEL = {
  approved: 'Approved',
  pending: 'Pending',
  rejected: 'Rejected',
};

export const PTO_STATUS_VALUE = {
  approved: 'approved',
  pending: 'pending',
  rejected: 'rejected',
};
