import { createGlobalStyle } from 'styled-components';
import { colors } from './theme';

const GlobalStyle = createGlobalStyle`
  html,
  body,
  * {
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    box-sizing: border-box;
  }

  *:focus-visible {
    outline: none;
    border-color: transparent;
    box-shadow: 0 0 0 2px ${colors.orangeGambage};
  }

  a:focus-visible {
    color: ${colors.orangeGambage};
  }

  html,
  body {
    height: 100%;
    min-height: 100vh;
  }

  body {
    margin: 0;
    padding: 0;
    background-color: ${colors.graySmoke};
    scroll-behavior: smooth;
  }

  #root {
    min-width: 960px;
    height: 100%;
  }

  a {
    color: ${colors.grayHoki};
  }

  ::-webkit-scrollbar {
    width: 11px;
    background: rgba(0, 0, 0, 0);
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 0;
    opacity: 0.7;

    &:hover {
      opacity: 0.8;
    }

    &:active {
      opacity: 0.75;
    }
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${colors.grayHoki};
  }

  .download-table-xls-button {
    outline: none;
  }

  .react-datepicker__close-icon::after {
    background: transparent;
    color: ${colors.grayCharcoal};
    font-size: 14px;
  }

  mark {
    background: rgb(246 177 0 / 50%)
  }

  .bg-success {
    background-color: ${colors.greenOlive};
  }

  .bg-info {
    background-color: ${colors.blueDenim};
  }

  .bg-alert {
    background-color: ${colors.orangeGambage};
  }

  .bg-error {
    background-color: ${colors.redRum};
  }
`;

export default GlobalStyle;
