import React from 'react';
import styled from 'styled-components';

import { colors } from 'theme/theme';
import Icon from './Icon';
import classNames from 'classnames';

const StyledStatCard = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 15px;
  border: 1px solid ${colors.grayLight};
  border-radius: 3px;
  background: ${colors.white};

  .StatCard__icon {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 999px;
    width: 45px;
    height: 45px;
    color: ${colors.white};
  }

  .StatCard__details {
    display: flex;
    flex-flow: column nowrap;
    gap: 8px;
    flex: 1 1 auto;
  }

  .StatCard__title {
    font-weight: 500;
    font-size: 18px;
    color: ${colors.black};
    line-height: 1;
  }

  .StatCard__description {
    font-weight: 400;
    color: ${colors.grayDim};
    font-size: 14px;
    line-height: 1;
  }
`;

const iconBackgroundClasses = {
  success: 'bg-success',
  info: 'bg-info',
  error: 'bg-error',
  alert: 'bg-alert',
};

const StatCard = ({ title, description, icon, iconSeverity }) => {
  const iconBackgroundClass = iconBackgroundClasses[iconSeverity] ?? iconBackgroundClasses.info;

  return (
    <StyledStatCard>
      <div className={classNames('StatCard__icon', iconBackgroundClass)}>
        <Icon type={icon} />
      </div>
      <div className="StatCard__details">
        <div className="StatCard__title">{title}</div>
        <div className="StatCard__description">{description}</div>
      </div>
    </StyledStatCard>
  );
};

export default StatCard;
