import range from 'lodash/range';
import dayjs from 'lib/dayjs';

import { Accessible } from 'components/Accessible';
import Banner from 'components/Banner';
import Checkbox from 'components/Checkbox';
import DatePicker from 'components/DatePicker';
import LabeledInput from 'components/LabeledInput';
import ModalWrapper from 'components/Modals/ModalWrapper';
import { Section } from 'components/Section';
import Select from 'components/Select';
import TextArea from 'components/TextArea';
import { aria } from 'constants/aria';
import { colors } from 'theme/theme';
import changeMethod from 'utils/changeMethod';
import { arrayToOptions, isWeekday } from 'utils/common';
import { ACTIVE_PTO_REASONS, optionsToGroups, PTO_REASON_KEYS, PTO_TYPES, referenceToOptions } from 'utils/reference';

import { Comment, Grid } from './PTORequestModal.styled';
import PTORequestModalStats from './PTORequestModalStats';

const WORKDAY_HOURS_INTERVAL = 2;
const WORKDAY_HOURS_MIN = 8;
const WORKDAY_HOURS_MAX = 12;

const PTO_HOURS_MIN = 0.5;
const PTO_HOURS_INTERVAL = 0.5;

const getDateFilters = includeWeekends => date => {
  if (includeWeekends) return true;
  return isWeekday(date);
};

const PTORequestModal = props => {
  const {
    confirmed: isConfirmed,
    MAX_COMMENT_LENGTH,
    details,
    exclusions,
    loadingRequest: isLoading,
    getDuration,
    onConfirm,
    onSubmit,
    onUpdate,
    onUpdatePreferences,
    validation,
    isDuplicateRequest,
    ...rest
  } = props;

  const {
    hoursWorkday,
    includeWeekends,
    reason,
    timeframeType,
    startDate,
    endDate,
    hours,
    comment,
    status,
    managerComment,
    managerName,
  } = details;

  const startOfLastYear = dayjs()
    .subtract(1, 'y')
    .startOf('y');

  const endOfThisYear = dayjs().endOf('y');

  const endOfNextYear = dayjs()
    .add(1, 'y')
    .endOf('y');

  const isValidFields = validation(details);
  const isValidRequest = !isLoading && !isDuplicateRequest && isValidFields && isConfirmed;

  const renderStatusMessage = () => {
    if (status === 'approved') {
      return (
        <Banner
          alert
          role="alert"
          aria-live="assertive"
          aria-label={aria.timeOff.editAlreadyApproved()}
          style={{ margin: '0 0 25px', fontSize: '12px', textTransform: 'none' }}
        >
          <p style={{ margin: 0 }}>
            You are currently editing an approved request. Submitting this request <em>will</em> require reapproval from
            your team lead!
          </p>
        </Banner>
      );
    } else if (status === 'rejected') {
      return (
        <Banner
          alert
          role="alert"
          aria-live="assertive"
          style={{ margin: '0 0 25px', fontSize: '12px', textTransform: 'none' }}
        >
          <h3 style={{ margin: '0 0 3px' }}>{managerName}'s Comment</h3>
          <p style={{ margin: 0 }}>{managerComment}</p>
        </Banner>
      );
    }
  };

  const renderDuplicatePtoMessage = () => {
    if (isDuplicateRequest) {
      return (
        <Banner
          alert
          role="alert"
          aria-live="assertive"
          aria-label={aria.timeOff.duplicateRequest()}
          style={{ margin: '0 0 25px', fontSize: '12px', textTransform: 'none' }}
        >
          <p style={{ margin: 0 }}>
            There is a time off request that already contains the selected date(s). Please edit the existing request
            instead.
          </p>
        </Banner>
      );
    }
  };

  const renderSpecialInputs = () => {
    const weekendOptions = [
      { label: 'Include Weekends', value: true },
      { label: 'Exclude Weekends', value: false },
    ];

    return (
      <Section label="Workday - Special Circumstances">
        <Grid>
          <LabeledInput label="Hours Per Workday">
            <Select
              aria-label={aria.timeOff.hoursWorkday()}
              options={arrayToOptions(
                range(WORKDAY_HOURS_MIN, WORKDAY_HOURS_MAX + WORKDAY_HOURS_INTERVAL, WORKDAY_HOURS_INTERVAL)
              )}
              onChange={changeMethod(onUpdatePreferences, 'hoursWorkday')}
              clearable={false}
              value={hoursWorkday}
            />
          </LabeledInput>
          <LabeledInput label="Weekends">
            <Select
              aria-label={aria.timeOff.includeWeekends()}
              options={weekendOptions}
              onChange={changeMethod(onUpdatePreferences, 'includeWeekends')}
              clearable={false}
              value={includeWeekends}
            />
          </LabeledInput>
        </Grid>
      </Section>
    );
  };

  const renderDurationInputs = () => {
    if (timeframeType === 'partial_day') {
      return (
        <>
          <LabeledInput
            label={
              <span>
                Date <Comment style={{ display: 'inline-flex', fontSize: 11, fontWeight: 300 }}> - MM/DD/YYYY</Comment>
              </span>
            }
          >
            <DatePicker
              ariaLabel={aria.timeOff.dateStartPartial()}
              selected={startDate}
              startDate={startDate}
              endDate={endDate}
              minDate={startOfLastYear}
              maxDate={reason === PTO_REASON_KEYS.office_closure ? endOfThisYear : endOfNextYear}
              onChange={changeMethod(onUpdate, 'startDate')}
              filterDate={getDateFilters(includeWeekends)}
              excludeDates={exclusions}
              utcOffset={0}
              isClearable
            />
          </LabeledInput>
          <LabeledInput label="Hours">
            <Select
              aria-label={aria.timeOff.hours({
                hoursInterval: PTO_HOURS_INTERVAL,
                hoursMin: PTO_HOURS_MIN,
                hoursMax: hoursWorkday,
              })}
              isDisabled={reason === PTO_REASON_KEYS.office_closure}
              options={arrayToOptions(range(PTO_HOURS_MIN, hoursWorkday + PTO_HOURS_INTERVAL, PTO_HOURS_INTERVAL))}
              onChange={changeMethod(onUpdate, 'hours')}
              clearable={false}
              value={parseFloat(hours)}
            />
          </LabeledInput>
        </>
      );
    } else {
      return (
        <>
          <LabeledInput
            label={
              <span>
                Start Date{' '}
                <Comment style={{ display: 'inline-flex', fontSize: 11, fontWeight: 300 }}> - MM/DD/YYYY</Comment>
              </span>
            }
          >
            <DatePicker
              ariaLabel={aria.timeOff.dateStartFull()}
              selected={startDate}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              minDate={endDate ? dayjs(endDate).startOf('y') : startOfLastYear}
              maxDate={endDate ? endDate : endOfNextYear}
              onChange={changeMethod(onUpdate, 'startDate')}
              filterDate={getDateFilters(includeWeekends)}
              excludeDates={exclusions}
              utcOffset={0}
              isClearable
            />
          </LabeledInput>
          <LabeledInput
            label={
              <span>
                End Date{' '}
                <Comment style={{ display: 'inline-flex', fontSize: 11, fontWeight: 300 }}> - MM/DD/YYYY</Comment>
              </span>
            }
          >
            <DatePicker
              ariaLabel={aria.timeOff.dateEndFull()}
              selected={endDate}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate ? startDate : startOfLastYear}
              maxDate={startDate ? dayjs(startDate).endOf('y') : endOfNextYear}
              onChange={changeMethod(onUpdate, 'endDate')}
              filterDate={getDateFilters(includeWeekends)}
              excludeDates={exclusions}
              utcOffset={0}
              isClearable
            />
          </LabeledInput>
        </>
      );
    }
  };

  const renderInputs = () => {
    const commentRemaining = MAX_COMMENT_LENGTH - comment.length;
    const ptoReasonOptions = referenceToOptions(ACTIVE_PTO_REASONS);
    const flexPtoOptions = [
      PTO_REASON_KEYS.pto,
      PTO_REASON_KEYS.office_closure,
      PTO_REASON_KEYS.bereavement,
      PTO_REASON_KEYS.jury_duty,
    ];
    const ptoReasonGroups = [{ label: 'Flex PTO', options: flexPtoOptions }];
    const ptoReasonOptionsGrouped = optionsToGroups(ptoReasonOptions, ptoReasonGroups);
    const defaultReason = ptoReasonOptions.find(option => option.value === reason);

    return (
      <Section label="Details">
        <Grid>
          <LabeledInput label="Reason">
            <Select
              aria-label={aria.timeOff.reason()}
              options={ptoReasonOptionsGrouped}
              onChange={changeMethod(onUpdate, 'reason')}
              clearable={false}
              defaultValue={defaultReason}
              value={reason}
              autoFocus
            />
          </LabeledInput>
          <LabeledInput label="Duration">
            <Select
              aria-label={aria.timeOff.duration()}
              options={referenceToOptions(PTO_TYPES)}
              onChange={changeMethod(onUpdate, 'timeframeType')}
              clearable={false}
              value={timeframeType}
              isDisabled={reason === PTO_REASON_KEYS.office_closure}
            />
          </LabeledInput>
          {renderDurationInputs()}
          <LabeledInput
            label={
              <span>
                Comments{' '}
                <Comment style={{ display: 'inline-flex', fontSize: 11, fontWeight: 300 }}> - Required</Comment>
              </span>
            }
            style={{ gridColumn: '1 / span 2' }}
          >
            <TextArea
              aria-label={aria.timeOff.comment()}
              name="comment"
              onChange={changeMethod(onUpdate)}
              value={comment}
              maxLength={MAX_COMMENT_LENGTH}
            />
            <Comment>
              Maximum {MAX_COMMENT_LENGTH} characters{' '}
              <span style={{ color: commentRemaining >= 0 ? 'inherit' : colors.redBrick }}>
                ({commentRemaining} remaining)
              </span>
            </Comment>
          </LabeledInput>
        </Grid>
      </Section>
    );
  };

  const renderConfirm = () => {
    return (
      <Checkbox
        checked={isConfirmed}
        aria-label={aria.timeOff.managerApproval()}
        label="My customer and/or engagement manager approves this request"
        style={{ marginTop: 10 }}
        onChange={onConfirm}
      />
    );
  };

  const renderStats = () => {
    return (
      <Section label="Request Breakdown">
        <PTORequestModalStats {...props} />
      </Section>
    );
  };

  const renderHelp = () => {
    const isParentalLeave = ['maternity_leave', 'paternity_leave'].includes(reason);

    return (
      <Accessible style={{ marginTop: 24 }}>
        {isParentalLeave && (
          <div style={{ marginBottom: 24 }}>
            Moser's Paid Parental Leave Policy (2 weeks) can be combined with our Flexible PTO policy (an additional 2
            weeks) and can provide up to 4 weeks of paid leave for a new parent.
          </div>
        )}
        Have questions? Check out the{' '}
        <a href="/help" target="_blank">
          Help page
        </a>
        .
      </Accessible>
    );
  };

  return (
    <ModalWrapper
      title="Request Time Off"
      cancelAriaLabel="Discard Changes to Time Off Request"
      closeAriaLabel="Submit Time Off Request"
      closeText="Submit"
      valid={isValidRequest}
      onClose={onSubmit}
      width={500}
      locked
      useCloseAction
      ignoreAutoFocus
      {...rest}
    >
      {renderStatusMessage()}
      {renderDuplicatePtoMessage()}
      {renderInputs()}
      {renderSpecialInputs()}
      {renderStats()}
      {renderConfirm()}
      {renderHelp()}
    </ModalWrapper>
  );
};

export default PTORequestModal;
