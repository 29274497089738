import styled from 'styled-components';

import StatCard from 'components/StatCard';
import { Container } from 'components/layout';
import dayjs from 'lib/dayjs';
import { useMemo } from 'react';
import { calculateApprovedHours, calculateOfficeClosures, calculateYtdHours } from 'utils/timeOff';

const StyledPTOStats = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(3, 1fr);
  padding: 25px 0;
`;

const PTOStats = ({ balances, requests }) => {
  const currentYear = dayjs().year();
  const approvedHours = useMemo(() => calculateApprovedHours(requests), [requests]);
  const ytdHours = useMemo(() => calculateYtdHours(requests), [requests]);
  const officeClosures = useMemo(() => calculateOfficeClosures(balances, { year: currentYear }), [
    balances,
    currentYear,
  ]);

  return (
    <Container>
      <StyledPTOStats>
        <StatCard
          title={`${approvedHours} Hours`}
          description={`${currentYear} Approved Time Off`}
          icon="check-circle"
          iconSeverity="success"
        />
        <StatCard
          title={`${ytdHours} Hours`}
          description={`${currentYear} YTD Time Off`}
          icon="calendar-o"
          iconSeverity="info"
        />
        <StatCard
          title={`${officeClosures.totalRequested} of ${officeClosures.totalBalance} Office Closures`}
          description={`${currentYear} Office Closure Balance`}
          icon="star"
          iconSeverity="alert"
        />
      </StyledPTOStats>
    </Container>
  );
};

export default PTOStats;
