import React from 'react';
import find from 'lodash/find';
import dayjs from 'lib/dayjs';

import { parsedFloat } from 'utils/common';

import { Stat } from './PTORequestModal.styled';
import Loader from 'components/Loader';
import { PTO_MINIMUM_HOURS } from 'lib/constants';
import { aria } from 'constants/aria';
import { PTO_REASON_KEYS } from 'utils/reference';
import Banner from 'components/Banner';

const PTORequestModalStats = ({
  balances,
  details,
  originalRequest,
  selectedYear,
  updating,
  getDuration,
  loadingBalances,
  validateLimit,
}) => {
  const { reason, startDate, endDate, hours, timeframeType } = details;
  const isCurrentYear = selectedYear === dayjs().year();
  const currentBalance = find(balances, { type: reason });
  const duration = getDuration(timeframeType, { startDate, endDate, hours });

  if (loadingBalances) return <Loader compact />;

  const renderOfficeClosureStats = requestDetails => {
    const totalBalanceHours = parseFloat(currentBalance?.accumulatedAmount ?? 0);
    const requestedThisYearHours = parsedFloat(currentBalance.ptoRequested[selectedYear] ?? 0);

    const requestedThisYearDays = requestedThisYearHours / 8;
    const requestedCurrentDays = requestDetails.duration / 8;

    const totalBalance = totalBalanceHours / 8;
    const nextTotalBalance = requestedThisYearDays + requestedCurrentDays;

    const isAboveLimit = nextTotalBalance > totalBalance;

    // Request Update
    return (
      <>
        <Stat aria-label={aria.timeOff.statDaysRequested({ daysCurrent: requestedCurrentDays })}>
          <strong>Office Closure</strong>
          <div>{requestedCurrentDays} Day</div>
        </Stat>
        <Stat error={isAboveLimit} aria-label={aria.timeOff.statDaysRequested({ daysCurrent: requestedCurrentDays })}>
          <strong>Office Closure Balance</strong>
          <div>
            {nextTotalBalance} of {totalBalance} Days
          </div>
        </Stat>
        {isAboveLimit && (
          <Banner alert>Office Closure balance exceeded. Delete an upcoming Office Closure then resubmit.</Banner>
        )}
      </>
    );
  };

  const renderStats = () => {
    const durationNum = parsedFloat(duration);
    let requestDetails = { duration: durationNum };

    // Office Closure - Show Holiday Stats
    if (reason === PTO_REASON_KEYS.office_closure) {
      return renderOfficeClosureStats(requestDetails);
    }

    // Balance Not Found for Type - Show Generic Hours
    if (!currentBalance) {
      return (
        <Stat aria-label={aria.timeOff.statHoursRequested({ hoursCurrent: details.duration })}>
          <strong>Hours Requested</strong>
          <div>{requestDetails.duration} Hours</div>
        </Stat>
      );
    }

    const yearText = isCurrentYear ? '' : `(${selectedYear})`;
    const minimumText = reason === 'pto' ? `(${PTO_MINIMUM_HOURS} Hours Minimum)` : '';
    const isAboveLimit = validateLimit(details);

    const requestedThisYearNum = parsedFloat(currentBalance.ptoRequested[selectedYear] || 0);
    const requestedThisYearFutureNum = parsedFloat(requestedThisYearNum + durationNum);
    requestDetails.requestedThisYearFuture = requestedThisYearFutureNum;

    // Request Update
    if (updating) {
      const { hours: durationOriginal } = originalRequest;
      const durationOriginalNum = parsedFloat(durationOriginal);
      const hoursDifferenceNum = durationNum - durationOriginalNum;
      const hoursDifferenceLabel = hoursDifferenceNum > 0 ? '+' : '';
      const hoursDifferenceWithLabel = hoursDifferenceLabel + hoursDifferenceNum;

      requestDetails.durationOriginal = durationOriginalNum;
      requestDetails.requestedThisYear = requestedThisYearNum;
      requestDetails.requestedThisYearChange = hoursDifferenceWithLabel;

      return (
        <>
          <Stat aria-label={aria.timeOff.statHoursOriginal({ hoursOriginal: requestDetails.durationOriginal })}>
            <strong>Original Request</strong>
            <div>{requestDetails.durationOriginal} Hours</div>
          </Stat>
          <Stat
            error={isAboveLimit}
            aria-label={aria.timeOff.statHoursRequested({ hoursCurrent: requestDetails.duration })}
          >
            <strong>Current Request</strong>
            <div>{requestDetails.duration} Hours</div>
          </Stat>
          <Stat
            aria-label={aria.timeOff.statHoursChange({
              hoursCurrent: requestDetails.duration,
              hoursDifference: requestDetails.requestedThisYearChange,
              hoursOriginal: requestDetails.durationOriginal,
            })}
          >
            <strong>Change in Hours</strong>
            <div>{requestDetails.requestedThisYearChange} Hours</div>
          </Stat>
          <Stat
            aria-label={aria.timeOff.statHoursYtdOriginal({
              currentYear: selectedYear,
              hoursOriginal: requestDetails.requestedThisYear,
            })}
          >
            <strong>YTD Before</strong>
            <div>{requestDetails.requestedThisYear} Hours</div>
          </Stat>
          <Stat
            aria-label={aria.timeOff.statHoursYtd({
              currentYear: selectedYear,
              hoursCurrent: requestDetails.requestedThisYearFuture,
            })}
          >
            <strong>
              YTD After {yearText} {minimumText}
            </strong>
            <div>{requestDetails.requestedThisYearFuture} Hours</div>
          </Stat>
        </>
      );
    }

    // Standard Request
    return (
      <>
        <Stat
          error={isAboveLimit}
          aria-label={aria.timeOff.statHoursRequested({ hoursCurrent: requestDetails.duration })}
        >
          <strong>Hours Requested</strong>
          <div>
            <strong>{requestDetails.duration} Hours</strong>
          </div>
        </Stat>
        <Stat
          aria-label={aria.timeOff.statHoursYtd({
            currentYear: selectedYear,
            hoursCurrent: requestDetails.requestedThisYearFuture,
          })}
        >
          <strong>
            YTD {yearText} {minimumText}
          </strong>
          <div>{requestDetails.requestedThisYearFuture} Hours</div>
        </Stat>
      </>
    );
  };

  return renderStats();
};

export default PTORequestModalStats;
